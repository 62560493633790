import config from '@/config/config'
import GoogleMapsApiLoader from 'google-maps-api-loader'

class GoogleService {
    #apiKey = config.googleService.mapApiKey

    #service = null

    #autocomplete = null

    #map = []

    #latLang = null

    #marker = null

    // constructor() {
    //     if (this.#service === null) {
    //         GoogleMapsApiLoader({
    //             libraries: ['places'],
    //             apiKey: this.#apiKey,
    //         }).then(api => {
    //             this.#service = api
    //             console.log(api, 'init')
    //         }, err => {
    //             throw err
    //         })
    //     }
    // }

    async setMaps(elMap, options, key = 'map') {
        if (this.#service === null) {
            this.#service = await GoogleMapsApiLoader({
                libraries: ['places'],
                apiKey: this.#apiKey,
            })
        }
        console.log(this.#service, 'setmaps')
        this.#map[key] = new this.#service.maps.Map(elMap, options)
    }

    getMaps(key = 'map') {
        return this.#map[key]
    }

    queryAutoComplete(request, callback) {
        if (!(this.#autocomplete instanceof this.#service.maps.places.AutocompleteService)) {
            this.#autocomplete = new this.#service.maps.places.AutocompleteService()
        }
        // console.log(this.#autocomplete)
        this.#autocomplete.getPlacePredictions(request, callback)
    }

    getDetailsPlace(request, callback, key = 'map') {
        const places = new this.#service.maps.places.PlacesService(this.#map[key])
        places.getDetails(request, callback)
    }

    setLatLng(latLng) {
        this.#latLang = new this.#service.maps.LatLng(latLng)
    }

    getLatLng() {
        return this.#latLang
    }

    setMarker(latLng, map, title, color) {
        let icon = null
        if (color) {
            icon = {
                url: `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
            }
        }

        this.#marker = new this.#service.maps.Marker({
            position: latLng,
            map,
            title,
            icon,
        })
    }

    getMarker() {
        return this.#marker
    }
}

export default new GoogleService()
