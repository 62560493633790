<template>
    <div>
        <v-select id="suggestion-place" v-model="inputAddress" label="description" :filterable="false"
            :options="addressOptions" @search="placeOnSearch" @option:selected="val => fetchAddressDetail(val.place_id)">
            <template slot="no-options"> type to search ... </template>
        </v-select>
        <div id="map" :style="`height: ${height}px; margin: 10px 0;`"></div>
        <!-- <span>Alamat: <a v-if="searchResult" :href="searchResult.url" target="_blank">{{ searchResult.formatted_address
        }}</a></span> -->
    </div>
</template>

<script>
import vSelect from 'vue-select'
import GoogleService from '@/libs/google-maps'

export default {
    components: {
        vSelect,
    },
    model: {
        prop: 'data',
        event: 'update:data',
    },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        state: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '200'
        },
        data: {
            type: Object,
            default: null
        },
        placeId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            addressOptions: [],
            map: null,
            inputAddress: null,
            searchResult: null,
        }
    },
    mounted() {
        // this.map = document.getElementById('map')
        // this.initMap({
        //     lat: -6.200000,
        //     lng: 106.816666,
        // })
        // this.getGeolocation().then(latLng => {
        //     GoogleService.setLatLng(latLng)
        //     this.initMap(latLng)
        // })
        if (this.placeId) {
            // this.fetchAddressDetail(this.placeId)
        }
    },
    methods: {
        async init() {
            const coord = await this.getGeolocation()
            if (coord) {
                await this.initMap(coord)
            }
        },
        placeOnSearch(search, loading) {
            this.placeFilter(search, loading, this)
        },
        placeFilter: _.debounce((search, loading, vm) => {
            console.log(vm)
            loading(true)
            vm.getAddressSugestion(search).then(res => {
                vm.addressOptions = res
                console.log(res)
            }).finally(() => {
                loading(false)
            })
        }, 1000),
        getAddressSugestion(input) {
            return new Promise((resolve, reject) => {
                try {
                    if (input === '') {
                        resolve([])
                    }

                    const request = {
                        input: input ?? '',
                        types: [],
                    }
                    GoogleService.queryAutoComplete(request, (predictions, status) => {
                        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve([])
                        }
                        resolve(predictions)
                    })
                } catch (error) {
                    reject(error)
                }
            })
        },
        getGeolocation() {
            return new Promise(resolve => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        const latLng = { lat: position.coords.latitude, lng: position.coords.longitude }
                        resolve(latLng)
                    })
                }
            }, reject => {
                reject()
            })
        },
        async initMap(latLng) {
            this.map = document.getElementById('map')
            await GoogleService.setMaps(this.map, {
                center: latLng,
                zoom: 17,
            })
            const selector = this
            GoogleService.getMaps().addListener('dragend', () => {
                const maps = GoogleService.getMaps()
                const { center } = maps
                GoogleService.setLatLng({
                    lat: center.lat(),
                    lng: center.lng(),
                })
                this.$emit('drag', { lat: center.lat(), lng: center.lng() })
                selector.getAddressSugestion('')
            })
        },
        setMapsMarker(latLng) {
            // console.log(latLng)
            GoogleService.setMarker(latLng, GoogleService.getMaps(), `${latLng.lat}, ${latLng.lng}`)
        },
        fetchAddressDetail(placeId) {
            const request = {
                placeId,
            }
            GoogleService.getDetailsPlace(request, this.displayPlaceInfo)
        },
        displayPlaceInfo(result, status) {
            const latLng = {
                lat: result.geometry.location.lat(), lng: result.geometry.location.lng(),
            }
            this.initMap(latLng)
            this.$emit('update:data', result)
            this.searchResult = result
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

body,
html,
#map {
    height: 100%;
    margin: 0
}

#map {
    position: relative;
}

#map:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png');
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}
</style>
