<template>
  <div>
    <b-sidebar id="add-new-user-sidebar" :visible="isAddNewPageSidebarActive" bg-variant="white"
      sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
      @change="val => $emit('update:is-add-new-page-sidebar-active', val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{
              typeForm === 'add'
              ? 'Add New'
              : typeForm === 'edit'
                ? 'Edit'
                : 'Detail'
            }}
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Full Name -->
            <validation-provider #default="validationContext" name="Kode Warehouse" rules="required">
              <b-form-group label="Kode Warehouse" label-for="warehouse-code">
                <b-form-input id="warehouse-code" v-model="form.warehouse_code" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="Kode Warehouse" :readonly="!canEdit" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Nama Warehouse" rules="required">
              <b-form-group label="Nama Warehouse" label-for="warehouse-name">
                <b-form-input id="warehouse-name" v-model="form.warehouse_name" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="Nama Warehouse" :readonly="!canEdit" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="area" rules="required">
              <b-form-group label="Area" label-for="area">
                <v-select id="warehouse-name" v-model="selectedArea" label="name" :filterable="false"
                  :options="areaOptions" @search="onSearch">
                  <template slot="no-options"> type to search ... </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Alamat" rules="required">
              <b-form-group label="Alamat" label-for="address">
                <b-form-input id="address" v-model="form.address" autofocus :state="getValidationState(validationContext)"
                  trim placeholder="Alamat" :readonly="!canEdit" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-button outlined variant="primary" class="w-100" @click="showPinMapModal" style="margin-bottom: 15px;">
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  {{ form.latitude && form.longitude ? 'Map Pointed' : 'Pin Poin' }}
                </span>
                <feather-icon icon="MapPinIcon"></feather-icon>
              </div>
            </b-button>
            <validation-provider #default="validationContext" name="Nama Penanggung Jawab" rules="required">
              <b-form-group label="Nama Penanggung Jawab" label-for="contact-name">
                <b-form-input id="contact-name" v-model="form.contact_name" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="Nama Penanggung Jawab"
                  :readonly="!canEdit" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="No. HP Penanggung Jawab" rules="required">
              <b-form-group label="No. HP Penanggung Jawab" label-for="contact-phone">
                <b-form-input id="contact-phone" v-model="form.contact_phone" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="No. HP Penanggung Jawab"
                  :readonly="!canEdit" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Email Penanggung Jawab" rules="required|email">
              <b-form-group label="Email Penanggung Jawab" label-for="contact-emailcontact-name">
                <b-form-input id="contact-email" v-model="form.contact_email" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="Email Penanggung Jawab"
                  :readonly="!canEdit" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Default" rules="required">
              <b-form-group label="Default" label-for="is_default">
                <b-form-checkbox id="is_default" v-model="form.is_default" name="check-button" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div v-if="typeForm !== 'show'" class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                :disabled="isLoading">
                <span>Submit</span>
                <b-spinner v-if="isLoading" small class="ml-1" />
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal v-model="pinMapModal" centered title="Pin Poin" scrollable ok-title="Submit" cancel-title="Cancel"
      @ok="setMapPoin" no-close-on-backdrop>
      <g-map ref="map" id="map-view" placeholder="Alamat Pada Map" height="200" :data.sync="mapData"
        :place-id="form.google_place_id" @drag="mapOnDrag" />
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { onMounted, ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { typeVoucherOptions } from '@core/utils/constant'
import store from '@/store'
import GMap from '@/components/GMap.vue'
import Vue from 'vue'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BFormDatepicker,
    BButton,
    vSelect,
    BSpinner,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    GMap
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPageSidebarActive',
    event: 'update:is-add-new-page-sidebar-active',
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: 'add',
    },
    data: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      areaOptions: [],
    }
  },
  methods: {
    onSearch(search, loading) {
      this.filter(search, loading, this)
    },
    filter: _.debounce((search, loading, vm) => {
      loading(true)
      store
        .dispatch('warehouses/shippingOnSearch', { search })
        .then(res => {
          vm.areaOptions = res.data.areas
        })
        .finally(() => {
          loading(false)
        })
    }, 1000),
  },
  setup(props, { emit, root }) {
    const blankFormData = {
      warehouse_code: null,
      warehouse_name: null,
      district: null,
      city: null,
      province: null,
      area_name: null,
      area_id: null,
      address: null,
      postal_code: null,
      is_default: false,
      contact_name: null,
      contact_phone: null,
      contact_email: null,
      latitude: null,
      longitude: null,
      google_place_id: null,
      google_map_link: null
    }

    const map = ref(null)
    const mapData = ref(null)

    const canEdit = ref(true)
    const selectedArea =
      props.data !== null
        ? ref({
          administrative_division_level_3_name: props.data.district,
          administrative_division_level_2_name: props.data.city,
          administrative_division_level_1_name: props.data.province,
          postal_code: props.data.postal_code,
          name: props.data.biteship_area_name,
          id: props.data.biteship_area_id,
        })
        : ref(null)

    const form =
      props.data !== null
        ? ref(props.data)
        : ref(JSON.parse(JSON.stringify(blankFormData)))
    const resetpageData = () => {
      form.value = JSON.parse(JSON.stringify(blankFormData))
      selectedArea.value = null
      mapData.value = null
    }

    watch(
      () => props.data,
      newValue => {
        console.log(form.value, newValue.is_default === 1)
        if (newValue !== null) {
          form.value.is_default = newValue.is_default === 1
          selectedArea.value = {
            administrative_division_level_3_name: newValue.district,
            administrative_division_level_2_name: newValue.city,
            administrative_division_level_1_name: newValue.province,
            postal_code: newValue.postal_code,
            name: newValue.biteship_area_name,
            id: newValue.biteship_area_id,
          }
        } else {
          selectedArea.value = null
          form.value.is_default = false
        }
      }
    )

    watch(
      () => props.isAddNewPageSidebarActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          form.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          form.value = props.data
        } else {
          canEdit.value = true
          form.value = JSON.parse(JSON.stringify(blankFormData))
        }
      }
    )

    const onSubmit = () => {
      // form.value.address = mapData.value.formatted_address
      if (form.value.is_default === null) {
        form.value.is_default = false
      }
      if (form.value.latitude && form.value.longitude) {
        if (props.typeForm === 'edit') {
          form.value.area_id = selectedArea.value.id
          form.value.area_name = selectedArea.value.name
        }
        // console.log(form.value, mapData.value)
        emit('onSubmit', form.value)
      } else {
        Vue.swal({
          title: 'Alert',
          icon: 'warning',
          text: 'Pin Poin Map harus diisi',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData)

    watch(
      () => selectedArea.value,
      newValue => {
        form.value.district =
          newValue === null
            ? null
            : newValue.administrative_division_level_3_name
        form.value.city =
          newValue === null
            ? null
            : newValue.administrative_division_level_2_name
        form.value.province =
          newValue === null
            ? null
            : newValue.administrative_division_level_1_name
        form.value.area_name = newValue === null ? null : newValue.name
        form.value.area_id = newValue === null ? null : newValue.id
        form.value.postal_code = newValue === null ? null : newValue.postal_code
      }
    )

    const pinMapModal = ref(false)
    const showPinMapModal = () => {
      // console.log(map.value)
      // map.value.init()
      pinMapModal.value = true
    }
    const setMapPoin = () => {
      form.value.latitude = mapData.value.geometry.location.lat()
      form.value.longitude = mapData.value.geometry.location.lng()
      // form.value.google_map_link = mapData.value.url
      // form.value.google_place_id = mapData.value.place_id
    }

    const mapOnDrag = (val) => {
      form.value.latitude = val.lat
      form.value.longitude = val.lng
      console.log(form.value)
    }

    onMounted(() => {
      root.$on('bv::modal::shown', (bvEvent, modalId) => {
        if (form.value.latitude && form.value.longitude) {
          map.value.initMap({ lat: parseFloat(form.value.latitude), lng: parseFloat(form.value.longitude) })
        } else {
          map.value.init()
        }
      })
    })

    return {
      tglLahir: null,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,

      form,
      // onSearch,
      // filter,
      selectedArea,
      map,
      mapData,
      pinMapModal,
      setMapPoin,
      showPinMapModal,
      mapOnDrag
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
